import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';

const AddMemberModal = ({ isOpen, toggle, project, updateProject }) => {
  const [email, setEmail] = useState('');

  const handleAddUser = () => {
    updateProject(project.id, email);
    setEmail('');
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {/* <ModalHeader toggle={toggle}>Add User to Project</ModalHeader> */}
      <ModalHeader>Add User to Project</ModalHeader>
      <ModalBody>
        <Input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Enter user email"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleAddUser}>Add User</Button>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddMemberModal ;
