import React, { useState } from 'react';
import './SetupScreen.css';

const steps = [
  { title: "Deploy Your App to the App Store", text: "Follow these simple steps to get your app from your repository to the app store." },
  { title: "Step 1: Create a Project", text: "Start by creating a new project. This will be your workspace for app deployment.", action: "Create Project" },
  { title: "Step 2: Connect Your GitHub Repository", text: "Connect the repository where your app's code is located.", action: "Connect Repository" },
  { title: "Step 3: Verify Your Google Play Store Account", text: "Ensure you have a Google Play Store account. This is needed to publish your app.", action: "Verify Account" },
  { title: "Step 4: Create a Bundle", text: "Generate a bundle of your app. This is required for the APK generation.", action: "Create Bundle" },
  { title: "Step 5: Service Account File", text: "Upload the service account file obtained from the Google Play Console.", action: "Upload File" },
  { title: "Step 6: Generate APK", text: "Convert your bundle into an APK, which is the file format accepted by Google Play Store.", action: "Generate APK" },
  { title: "Step 7: Add Release Notes and Choose Track", text: "Provide notes about this release and choose the track for your app’s release.", action: "Add Notes" },
  { title: "Step 8: Upload Images and Logo", text: "Upload the visual assets for your app, including screenshots and logo.", action: "Upload Assets" },
  { title: "Final Step: Review and Submit", text: "Review all the details and submit your app for deployment to the app store.", action: "Review & Submit" }
];

const SetupScreen = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = steps.length;

  const goToNextStep = () => setCurrentStep(currentStep < totalSteps ? currentStep + 1 : currentStep);
  const goToPreviousStep = () => setCurrentStep(currentStep > 1 ? currentStep - 1 : currentStep);

  return (
    <div className="setup-screen">
      <div className="progress-bar">
        <div className="progress" style={{ width: `${(currentStep / totalSteps) * 100}%` }}></div>
      </div>

      <div className="step-content">
        <h2>{steps[currentStep - 1].title}</h2>
        <p>{steps[currentStep - 1].text}</p>
        {/* Optionally, display action buttons or form elements based on current step */}
      </div>

      <div className="navigation-buttons">
        <button onClick={goToPreviousStep} disabled={currentStep === 1}>Previous</button>
        <button onClick={goToNextStep} disabled={currentStep === totalSteps}>Next</button>
      </div>

      <div className="help-links">
        <a href="/help">Help & Support</a>
      </div>
    </div>
  );
};

export default SetupScreen;
