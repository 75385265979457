import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    {/* <div className="logo" /> */}
    <p>
      Experimenting with simplicity<a href="https://deploycode.co.uk"> DeployCode</a>
    </p>
  </footer>
);

export default Footer;
