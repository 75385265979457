import React, { useState, useEffect } from "react";

const Hero = () => {
  const words = ['iOS', 'Android', 'web', 'mobile'];
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [index, setIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [isWaitlisted, setIsWaitlisted] = useState(false);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(i => {
        const newIndex = i + 1 === words.length ? 0 : i + 1;
        setCurrentWord(words[newIndex]);
        return newIndex;
      });
    }, 3000); // Change the word every 3 seconds

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, []);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handleWaitlistSubmit = () => {
    if (validateEmail(email)) {
      // Send a POST request to your Flask server
      fetch('http://localhost:5000/api/addToWaitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          setIsWaitlisted(true);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      setEmailError("Please enter a valid email address");
    }
  };


  return (
    <div className="text-center hero my-5">
      <h1 className="mb-4">Deploy your <span className="word-animate">{currentWord}</span> apps</h1>
      <p className="lead no-margin"> 
        This is a simple application that allows you to deploy your mobile apps without all the configuration headaches.
      </p>

      {!isWaitlisted ? (
        <div className="no-margin"> 
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
            className="form-control"
          />
          {emailError && <p className="text-danger">{emailError}</p>}
          <button className="btn btn-primary mt-2" onClick={handleWaitlistSubmit}>
            Join the Waitlist
          </button>
        </div>
      ) : (
        <p className="text-success">You've been added to the waitlist!</p>
      )}
    </div>

  );
};

export default Hero;
