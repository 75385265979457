import React from 'react';

const PricingPlan = ({ plan, price, description, features, buttonLabel }) => (
  <div className="pricing-plan">
    <h2>{plan}</h2>
    <p>{description}</p>
    <p className="price">${price}/mo</p>
    <ul>
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
    <button>{buttonLabel}</button>
  </div>
);

export default PricingPlan;
