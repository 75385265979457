import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCogs, faEdit, faTrash, faPaperPlane, faCheck, faTimes, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Avvvatars from 'avvvatars-react';
import { db } from '../firebase';
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0
import AddMemberModal from './AddMemberModal';
import ProjectSettingsModal from './ProjectSettingsModal';
import { arrayUnion, doc, updateDoc, arrayRemove } from 'firebase/firestore';
import ProjectCard from './ProjectCard';
import { collection, onSnapshot, query, where } from 'firebase/firestore';


const ProjectList = ({ onDragEndProp }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [invitedProjects, setInvitedProjects] = useState([]);

  const { user } = useAuth0(); // Get the user information from Auth0
  const userEmail = user.email; // Extract the email from the user object

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  const removeMemberFromProject = async (projectId, memberEmail) => {
    try {
      const projectRef = doc(db, 'projects', projectId);
      await updateDoc(projectRef, {
        members: arrayRemove(memberEmail)
      });
      // Optionally, refresh the project list or update the state
    } catch (error) {
      console.error('Error removing member from project:', error);
    }
  };

  // Function to update project data (to add users)
  const updateProject = async (projectId, userEmail) => {
    try {
      const projectRef = doc(db, 'projects', projectId); // Reference to the project document
      await updateDoc(projectRef, {
        members: arrayUnion(userEmail) // Update the members array
      });
      // Optionally, fetch the updated project list or update the state
    } catch (error) {
      console.error(`Error updating project: ${error}`);
    }
  };


  const [projects, setProjects] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [editFormValues, setEditFormValues] = useState({
    projectName: "",
    description: "",
    githubRepo: "",
  });
  const history = useHistory(); // Access the history object
  const [selectedDeployProjectId, setSelectedDeployProjectId] = useState(null);

  const toggleFavorite = async (projectId) => {
    setFavorites((prevFavorites) => {
      const isFavorited = prevFavorites.includes(projectId);
      const updatedFavorites = isFavorited
        ? prevFavorites.filter(id => id !== projectId)
        : [...prevFavorites, projectId];
  
      // Update the database
      const projectRef = doc(db, 'projects', projectId);
      updateDoc(projectRef, {
        isFavorited: !isFavorited
      }).catch(error => {
        console.error('Error updating favorite status:', error);
      });
  
      return updatedFavorites;
    });
  };
  


// Separate projects into favorited and others
const favoritedProjects = projects.filter(project => favorites.includes(project.id));
const otherProjects = projects.filter(project => !favorites.includes(project.id));


  const handleEdit = (projectId) => {
    const projectToEdit = projects.find((project) => project.id === projectId);
    if (projectToEdit) {
      setEditingProjectId(projectId);
      setEditFormValues({
        projectName: projectToEdit.projectName,
        description: projectToEdit.description,
        githubRepo: projectToEdit.githubRepo,
      });
    }
  };

  const handleDelete = async (projectId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this project?");

    if (confirmDelete) {
      try {
        // Delete the project document from Firebase Firestore
        await db.collection('projects').doc(projectId).delete();

        // Update the UI by removing the deleted project
        setProjects(projects.filter((project) => project.id !== projectId));
        console.log(`Project with ID ${projectId} has been deleted.`);
      } catch (error) {
        console.error(`Error deleting project: ${error}`);
      }
    }
  };

  const handleDeploy = (projectId) => {
    setSelectedDeployProjectId(projectId);
    const deployUrl = `/deploy/${projectId}`;
    history.push(deployUrl);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // If there's no destination (dragged outside), do nothing
    }
  
    const reorderedProjects = Array.from(projects);
    const [reorderedItem] = reorderedProjects.splice(result.source.index, 1);
    reorderedProjects.splice(result.destination.index, 0, reorderedItem);
  
    setProjects(reorderedProjects);
    // Optionally, update the order in your database as well
  };
  
  const handleSave = async (projectId) => {
    try {
      // Update the project document in Firebase Firestore
      await db.collection('projects').doc(projectId).update({
        projectName: editFormValues.projectName,
        description: editFormValues.description,
        githubRepo: editFormValues.githubRepo,
      });

      // Update the UI to reflect the changes
      setEditingProjectId(null); // Exit edit mode
      const updatedProjects = projects.map((project) => {
        if (project.id === projectId) {
          return {
            ...project,
            projectName: editFormValues.projectName,
            description: editFormValues.description,
            githubRepo: editFormValues.githubRepo,
          };
        }
        return project;
      });
      setProjects(updatedProjects);

      console.log(`Changes saved for project with ID: ${projectId}`);
    } catch (error) {
      console.error(`Error saving changes: ${error}`);
    }
  };

  const handleCancelEdit = () => {
    setEditingProjectId(null); // Exit edit mode
  };

  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);

  const openAddMemberModal = (project) => {
    setSelectedProject(project);
    setIsAddMemberModalOpen(true);
  };

  const userId = user.sub; // Or any unique identifier from the user object


  useEffect(() => {
    const userId = user.sub; // or user.email, depending on your use case

    const unsubscribe = onSnapshot(
      query(collection(db, "projects"), where("userId", "==", userId)),
      (snapshot) => {
        const updatedProjects = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProjects(updatedProjects);
      }
    );

    return () => unsubscribe();
  }, [user.sub]); // or [user.email]


  useEffect(() => {
    // Function to fetch the projects created by the user
    const fetchOwnProjects = async () => {
      try {
        const response = await db.collection('projects')
          .where('userId', '==', userId) // Filter projects by user's ID
          .get();
        const projectData = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setProjects(projectData);

       // Update favorites state
      const fetchedFavorites = projectData
      .filter(project => project.isFavorited)
      .map(project => project.id);
    setFavorites(fetchedFavorites);

      } catch (error) {
        console.error('Error fetching project data', error);
      }
    };

    // New function to fetch projects where the user is a member
    const fetchInvitedProjects = async () => {
      try {
        const response = await db.collection('projects')
          .where('members', 'array-contains', userEmail) // Use userEmail for the query
          .get();
        const projectData = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInvitedProjects(projectData); // Update the state with invited projects
      } catch (error) {
        console.error('Error fetching invited projects', error);
      }
    };

    fetchOwnProjects();
    fetchInvitedProjects();
  }, [userEmail, userId]);

  const isProjectEditable = (project) => {
    return project.userId === userId; // Or any appropriate logic
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
      <h3>Favorited Projects</h3>
        <div className="projects-container">
        {favoritedProjects.map((project, index) => (
            <ProjectCard
              key={project.id}
              project={project}
              toggleFavorite={() => toggleFavorite(project.id)}
              // ... other props ...
              favorites={favorites}
            />
          ))}
        </div>

        <hr />

        {/* ... Droppable and Draggable setup for owned projects ... */}
        <h3>Projects</h3>
        <Droppable droppableId="projectsDroppable">
          {(provided) => (
            <div
              className="projects-container"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {projects.map((project, index) => (
                <Draggable key={project.id} draggableId={project.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      
                      <ProjectCard
                        key={project.id}
                        project={project}
                        isEditable={isProjectEditable(project)}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onDeploy={handleDeploy}
                        onAddMember={openAddMemberModal}
                        onRemoveMember={removeMemberFromProject}
                        onSave={handleSave}
                        onCancelEdit={handleCancelEdit}
                        editingProjectId={editingProjectId}
                        editFormValues={editFormValues}
                        setEditFormValues={setEditFormValues}
                        favorites={favorites}
                        toggleFavorite={() => toggleFavorite(project.id)}
                      />
                      
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <hr />

      {/* Render Invited Projects using ProjectCard */}
      <h3>Projects You're Invited To</h3>
      <div className="projects-container">
        {/* <div className="invited-projects"> */}

        {invitedProjects.map((project) => (
          <ProjectCard
            key={project.id}
            project={project}
            isEditable={isProjectEditable(project)}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onDeploy={handleDeploy}
            onAddMember={openAddMemberModal}
            onRemoveMember={removeMemberFromProject}
            onSave={handleSave}
            onCancelEdit={handleCancelEdit}
            editingProjectId={editingProjectId}
            editFormValues={editFormValues}
            setEditFormValues={setEditFormValues}
            favorites={favorites}
            toggleFavorite={toggleFavorite}
          />
        ))}
        {/* </div> */}
      </div>


      {/* Add Member Modal */}
      <AddMemberModal
        isOpen={isAddMemberModalOpen}
        toggle={() => setIsAddMemberModalOpen(false)}
        project={selectedProject}
        updateProject={updateProject}
      // ...other props and functions...
      />
    </>
  );


};

export default ProjectList;
