import React from 'react';
import PricingPlan from './PricingPlan';

const Pricing = () => {
  const plans = [
    {
      name: '🌱 Starter',
      price: '15',
      description: "Perfect for hobby projects or small tests.",
      features: ['5 Projects', '10 GB Storage', 'Community Support', 'Basic Deployment Options'],
      buttonLabel: 'Sign Up'
    },
    {
      name: '🚀 Pro',
      price: '50',
      description: "Ideal for professionals and small businesses.",
      features: ['25 Projects', '100 GB Storage', 'Priority Email Support', 'Advanced Deployment Tools', 'CI/CD Integration'],
      buttonLabel: 'Sign Up'
    },
    {
      name: '🏢 Enterprise',
      price: '150',
      description: "Designed for large organizations with extensive requirements.",
      features: ['Unlimited Projects', '1 TB Storage', 'Dedicated Support', 'Full Analytics Suite', 'Custom Integration Options', '99.9% Uptime SLA'],
      buttonLabel: 'Sign Up'
    }
  ];

  return (
    <div className="pricing-container">
      {plans.map((plan, index) => (
        <PricingPlan
          key={index}
          plan={plan.name}
          price={plan.price}
          description={plan.description}
          features={plan.features}
          buttonLabel={plan.buttonLabel}
        />
      ))}
    </div>
  );
}

export default Pricing;
