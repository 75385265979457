import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import contentData from "../utils/contentData";

class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5">
        <h2 className="my-5 text-center">What will we do next?</h2>

        {/* Check if contentData is defined and is an array */}
        {Array.isArray(contentData) && contentData.map((item, index) => {
          if (item.logos) {
            // Render logos
            return (
              <Row key={index} className="justify-content-center my-4">
                {item.logos.map((logo, logoIndex) => (
                  <Col key={logoIndex} md={3} sm={6} className="text-center mb-4">
                    <img src={logo.src} alt={logo.alt} style={{ maxWidth: '150px' }} />
                  </Col>
                ))}
              </Row>
            );
          } else {
            // Render regular content
            return (
              <div key={index}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <a href={item.link}>Learn more</a>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default Content;
