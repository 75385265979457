// Home.js
import React, { Fragment, useState } from "react";

import ConnectHero from "../components/ConnectHero";
import ProjectList from "../components/ProjectList";
import CreateProjectForm from "../components/CreateProjectForm";

const Home = () => {
  const [projects, setProjects] = useState([]); // This would be fetched from a server in a real app

  const addProject = (project) => {
    console.log("Submitted project:", project);
    // Here, you would typically send the project data to your server
    setProjects([...projects, project]);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Dropped outside the list
  
    const reorderedProjects = [...projects];
    const [movedProject] = reorderedProjects.splice(result.source.index, 1); // Remove the dragged project
    reorderedProjects.splice(result.destination.index, 0, movedProject); // Insert the project at the new position
  
    // Update the state with the new order of projects
    setProjects(reorderedProjects);
  };
  
  return (
    <Fragment>
      <ConnectHero />
      {/* <ProjectList projects={projects} onDragEnd={handleDragEnd} />
      <hr />
      <CreateProjectForm onSubmit={addProject} />
      <hr /> */}
    </Fragment>
  );
};

export default Home;
