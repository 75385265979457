import firebase from 'firebase/compat/app'; // Use 'compat' to import Firebase v8
import 'firebase/compat/firestore'; // Use 'compat' to import Firestore v8

const firebaseConfig = {
    apiKey: "AIzaSyCOiumK7IOFAkFTW6ayPKPkM8i8NlXX1Qg",
    authDomain: "deploycodedotio.firebaseapp.com",
    projectId: "deploycodedotio",
    storageBucket: "deploycodedotio.appspot.com",
    messagingSenderId: "898680642629",
    appId: "1:898680642629:web:eca1b7d4c72ea3b9b8172c",
    measurementId: "G-9Y5P9JS0VE"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Export Firestore instance
const db = firebase.firestore();

export { db };