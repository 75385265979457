const contentData = [
  // ... Your existing data objects
   // Adding a new section for logos
   {
    logos: [
      { src: "../assets/logo1.png", alt: "Company 1" },
      { src: "../assets/logo2.png", alt: "Company 2" },
      // Add more logos here
    ]
  },
  {
    title: "Configure Other Identity Providers",
    link: "https://auth0.com/docs/connections",
    description:
      "Easily configure various identity providers with one-click integration. Auth0 supports a wide range of identity providers, including social providers like Facebook, Twitter, Instagram, and many more. You can also integrate with enterprise providers such as Microsoft Office 365, Google Apps, Azure, and more. Additionally, our platform allows seamless integration with any OAuth2 Authorization Server, simplifying the authentication process."
  },
  {
    title: "Enhance Security with Multifactor Authentication",
    link: "https://auth0.com/docs/multifactor-authentication",
    description:
      "Take your app's security to the next level by enabling Multifactor Authentication (MFA) with just one click. MFA adds an extra layer of protection by requiring users to provide multiple forms of identification. Our platform supports various MFA methods, including push notifications, authenticator apps, SMS, and DUO Security. With a single click, you can enhance user security and protect sensitive data."
  },
  {
    title: "Detect Anomalies and Prevent Unauthorized Access",
    link: "https://auth0.com/docs/anomaly-detection",
    description:
      "Our advanced anomaly detection feature helps you detect and prevent malicious attempts to access your application. With one click, you can enable anomaly detection, which alerts you and your users to suspicious activity. It also has the capability to block further login attempts, ensuring the security of your app and user data."
  },
  {
    title: "Customize and Extend with Rules",
    link: "https://auth0.com/docs/rules",
    description:
      "Customization is made simple with our Rules feature. These JavaScript functions execute seamlessly when a user authenticates to your application. They run after the authentication process is complete, allowing you to fully customize and extend Auth0's capabilities. With just one click, you can harness the power of Rules to tailor your app to your exact needs."
  },
];

export default contentData;
