import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import Modal from 'react-modal'; // Assuming you're using 'react-modal'

Modal.setAppElement('#root'); // Assuming your root element has id 'root'

const ConnectModal = ({ isOpen, onClose, onConnect }) => {
  const words = ['iOS', 'Android', 'web', 'mobile'];
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [index, setIndex] = useState(0);
  const [auth0Token, setAuth0Token] = useState(""); // Assuming you have the auth0Token

  const GITHUB_AUTH_URL = `https://github.com/login/oauth/authorize?client_id=fc170a1de9752c003320&scope=repo&redirect_uri=http://localhost:3000/auth/github/callback`;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(i => {
        const newIndex = i + 1 === words.length ? 0 : i + 1;
        setCurrentWord(words[newIndex]);
        return newIndex;
      });
    }, 3000); // Change the word every 3 seconds

    fetch('http://localhost:3000/api/data', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [auth0Token]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Hero Modal" className="modal" overlayClassName="overlay">
      <div className="text-center hero my-5">
        <h1 className="mb-4">Connect your <span className="word-animate">{currentWord}</span> apps</h1>
        <p className="lead">
          Add your projects to deploy and track them.
        </p>
        <div className="text-center">
          <button
            className="btn btn-primary"
            onClick={() => window.location.href = GITHUB_AUTH_URL}
          >
            <FontAwesomeIcon icon={faGithub} className="mr-2" />
            Login with GitHub
          </button>
        </div>
        <button onClick={onConnect}>Connect</button>
      <button onClick={onClose}>Close</button>
      </div>
    </Modal>
  );
};

export default ConnectModal;
