// import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faPaperPlane, faEdit, faTrash, faStar, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avvvatars from 'avvvatars-react';
import { faUserMinus } from "@fortawesome/free-solid-svg-icons"; // Import the icon for deleting a member
import React, { useState, useEffect } from 'react';
import ConnectModal from './ConnectModal';
import { faCogs } from '@fortawesome/free-solid-svg-icons'; 

const ProjectCard = ({ onRemoveMember, project, isEditable, onEdit, onDelete, onDeploy, onAddMember, onSave, onCancelEdit, editingProjectId, editFormValues, setEditFormValues, favorites, toggleFavorite }) => {
    const [isConnected, setIsConnected] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleConnection = () => {
        // Implement your connection logic here
        // After a successful connection, set isConnected to true
        setIsConnected(true);
        setShowModal(false); // Close the modal after connecting
    };

    const openModal = () => {
        setShowModal(true);
    };

    return (
        <div className={`project ${editingProjectId === project.id ? "editing" : ""}`}>
            <div className="project-header">
                <div className="project-avatar">
                    <Avvvatars style="shape" value={project.description} size={50} />
                </div>
                <div className="project-title">
                    <h3>
                        {editingProjectId === project.id ? (
                            <input
                                type="text"
                                value={editFormValues.projectName}
                                onChange={(e) => setEditFormValues({ ...editFormValues, projectName: e.target.value })}
                                className="edit-input"
                            />
                        ) : (
                            project.projectName
                        )}
                    </h3>
                </div>
            </div>
            <div className="project-description">
                {editingProjectId === project.id ? (
                    <textarea
                        value={editFormValues.description}
                        onChange={(e) => setEditFormValues({ ...editFormValues, description: e.target.value })}
                        className="edit-textarea"
                    />
                ) : (
                    <p>{project.description}</p>
                )}
            </div>

            {isConnected ? (
                <span>GitHub Repo Connected</span>
            ) : (
                <a onClick={openModal}>
                    Connect Github Repo
                </a>
            )}

            {showModal && (
                <ConnectModal
                    onConnect={handleConnection}
                    onClose={() => setShowModal(false)}
                />
            )}

            {/* GitHub Repo Link */}
            {project.githubRepo && (
                <p>
                    GitHub Repo URL:
                    <a href={project.githubRepo} target="_blank" rel="noopener noreferrer">
                        {project.githubRepo}
                    </a>
                </p>
            )}

            {/* Members List with Delete Option */}
            {project.members && (
                <div className="project-members">
                    <strong>Members:</strong>
                    <ul className="members-list">
                        {project.members.map((member, index) => (
                            <li key={index} className="member-item">
                                <span className="member-email">{member}</span>
                                {isEditable && (
                                    <FontAwesomeIcon
                                        icon={faUserMinus}
                                        onClick={() => onRemoveMember(project.id, member)}
                                        style={{ cursor: 'pointer', color: 'red' }}
                                        title="Remove Member"
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div className="project-actions">
                {/* Render actions based on whether the project is editable */}
                {isEditable && (
                    <>
                        {editingProjectId === project.id ? (
                            <>
                                <FontAwesomeIcon icon={faCheck} className="save-icon" onClick={() => onSave(project.id)} />
                                <FontAwesomeIcon icon={faTimes} className="cancel-icon" onClick={onCancelEdit} />
                            </>
                        ) : (
                            <>
                                {/* <Link to={`/setup/${project.id}`}>
                                    <FontAwesomeIcon icon={faPaperPlane} className="deploy-icon" onClick={() => onDeploy(project.id)} />
                                </Link> */}
                                <Link to={`/setupscreen`}>
                                    <FontAwesomeIcon icon={faCogs} className="setup-icon" />
                                </Link>
                                <Link to={`/deploy/${project.id}`}>
                                    <FontAwesomeIcon icon={faPaperPlane} className="deploy-icon" onClick={() => onDeploy(project.id)} />
                                </Link>
                                <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={() => onEdit(project.id)} />
                                <FontAwesomeIcon icon={faUserPlus} className="add-member-icon" onClick={() => onAddMember(project)} />
                                <FontAwesomeIcon icon={faTrash} className="delete-icon" onClick={() => onDelete(project.id)} />
                                <FontAwesomeIcon icon={faStar} className={`favorite-icon ${favorites.includes(project.id) ? "favorited" : ""}`} onClick={() => toggleFavorite(project.id)} />
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ProjectCard;
