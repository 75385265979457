import React, { useState } from 'react';
import { db } from '../firebase';
import { useAuth0 } from "@auth0/auth0-react"; // Import the useAuth0 hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CreateProjectForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [githubRepo, setGithubRepo] = useState('');
  const { user } = useAuth0(); // Use the useAuth0 hook to get user information
  const userId = user.sub; // Or any unique identifier from the user object

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add a new project document to the "projects" collection in Firestore with the user's ID
      await db.collection('projects').add({
        projectName,
        description,
        githubRepo,
        userId, // Include the user's ID with the project
        // You can add more fields as needed
      });

      // Clear form fields after submission
      setProjectName('');
      setDescription('');
      setGithubRepo('');

      // Hide the form after successful submission
      setShowForm(false);

      console.log('Project added to Firestore');
    } catch (error) {
      console.error('Error adding project to Firestore', error);
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div className="create-project-form">
      {!showForm ? (
        <div className="button-container">
          <button onClick={toggleForm} className="setup-button">Setup Project</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="project-form">
          <div className="form-header">
            <button onClick={toggleForm} className="close-button">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="projectName">Project Name:</label>
            <input
              type="text"
              id="projectName"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="githubRepo">GitHub Repository:</label>
            <input
              type="text"
              id="githubRepo"
              value={githubRepo}
              onChange={(e) => setGithubRepo(e.target.value)}
              className="form-control"
            />
          </div>
          <button type="submit" className="submit-button">Create Project</button>
        </form>
      )}
    </div>
  );
};

export default CreateProjectForm;
