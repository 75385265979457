import React, { useState, useEffect } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { createRoot } from 'react-dom/client';
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import axios from 'axios'; // Import Axios
import { db } from '../firebase';


const root = createRoot(document.getElementById('root'));

function App() {
  const { projectId } = useParams();
  const [message, setMessage] = useState('');
  const [androidProjectPath, setAndroidProjectPath] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [projectInfo, setProjectInfo] = useState(null);
  const [formData, setFormData] = useState({
    serviceAccountFile: null,
    packageName: '',
    apkFile: null,
    releaseNotes: '',
    track: 'production'
  });
  const [errors, setErrors] = useState({});

  const handlePathChange = (event) => {
    setAndroidProjectPath(event.target.value);
  };

  useEffect(() => {
    // Fetch project information using the projectId
    const fetchProjectInfo = async () => {
      try {
        const projectRef = db.collection('projects').doc(projectId); // Adjust the collection name as needed
        const projectDoc = await projectRef.get();

        if (projectDoc.exists) {
          const projectData = projectDoc.data();
          console.log('Fetched project data:', projectData);
          setProjectInfo(projectData);
        } else {
          console.error(`Project with ID ${projectId} not found.`);
        }
      } catch (error) {
        console.error(`Error fetching project data: ${error}`);
      }
    };

    fetchProjectInfo();
  }, [projectId]);

  const handleBuildBundle = async () => {
    try {
      const response = await fetch('http://localhost:5000/build-bundle', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ androidProjectPath }),
      });
      const result = await response.json();
      setMessage(result.message);
    } catch (error) {
      setMessage('Failed to connect to the server.');
    }
  };
  const handleDirectoryChange = (event) => {
    setSelectedFiles(event.target.files);
    // You can process the files here as needed
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    // Clear the error for this field
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear the error for this field
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleBuildApk = async () => {
    try {
      const response = await fetch('http://localhost:5000/build-apk', {
        method: 'POST',
        headers: {
          // Add headers if needed, e.g., for authorization
        },
      });

      const result = await response.json();
      if (response.ok) {
        setMessage(result.message); // Update state with the response message
        setTimeout(() => setMessage(''), 5000); // Reset message after 5 seconds
      } else {
        setMessage("Error: " + result.message); // Handle error messages
      }
    } catch (error) {
      console.error("An error occurred while connecting to the server.");
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!validateForm()) return;

    // event.preventDefault();

    const data = new FormData();
    data.append('serviceAccountFile', formData.serviceAccountFile); // Check if formData.serviceAccountFile is a file
    data.append('packageName', formData.packageName);
    data.append('apkFile', formData.apkFile); // Check if formData.apkFile is a file
    data.append('releaseNotes', formData.releaseNotes);
    data.append('track', formData.track);
    // Add other form fields as needed...

    console.log("FormData:", formData);

    setIsLoading(false);

    try {
      const response = await fetch('http://localhost:5000/deploy', {
        method: 'POST',
        body: data,
      });

      const result = await response.json();
      if (response.ok) {
        alert(result.message);  // Display success message
      } else {
        alert("Error: " + result.message);  // Display error message
      }
    } catch (error) {
      alert("An error occurred while connecting to the server.");
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.serviceAccountFile) newErrors.serviceAccountFile = 'Service account file is required.';
    if (!formData.packageName) newErrors.packageName = 'Package name is required.';
    if (!formData.apkFile) newErrors.apkFile = 'APK file is required.';
    if (!formData.releaseNotes) newErrors.releaseNotes = 'Release notes are required.';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="App">
      <div className="column-container">
        <div className="column">
          <div>
            <h1>Deploy Code</h1>
            <div style={{ textAlign: 'left' }}>
              {/* Display project information here */}
              {projectInfo ? (
                <>
                  {/* <p>Project ID: {projectInfo.id}</p> */}
                  <p>Project Name: {projectInfo.projectName}</p>
                  <p>Description: {projectInfo.description}</p>
                  <p>Github Repo: {projectInfo.githubRepo}</p>
                  {/* Add more project details as needed */}
                </>
              ) : (
                <p>Loading project information...</p>
              )}
              {/* Other form elements */}
            </div>

          </div>
          <input
            type="text"
            value={androidProjectPath}
            onChange={handlePathChange}
            placeholder="Enter Android project path"
          />
          <button onClick={handleBuildBundle}>Build App Bundle</button>
          {message && <p>{message}</p>}

        </div>
        <div className="column">
          <div className="form-container">
            <h1>Application Info</h1>
            <form onSubmit={handleSubmit}>
              {/* Service Account File Field */}
              <div className="form-field">
                <label htmlFor="serviceAccountFile">Service Account File:</label>
                <input
                  type="file"
                  id="serviceAccountFile"
                  name="serviceAccountFile"
                  onChange={handleFileChange}
                />
                {errors.serviceAccountFile && <span className="error">{errors.serviceAccountFile}</span>}
              </div>

              {/* Package Name Field */}
              <div className="form-field">
                <label htmlFor="packageName">Package Name:</label>
                <input
                  type="text"
                  id="packageName"
                  name="packageName"
                  value={formData.packageName}
                  onChange={handleChange}
                  placeholder="Package Name"
                />
                {errors.packageName && <span className="error">{errors.packageName}</span>}
              </div>

              {message && <div className="message">{message}</div>}
              <button type="button" onClick={handleBuildApk}>Generate APK</button>

              {/* APK File Field */}
              <div className="form-field">
                <label htmlFor="apkFile">APK File:</label>
                <input
                  type="file"
                  id="apkFile"
                  name="apkFile"
                  onChange={handleFileChange}
                />
                {errors.apkFile && <span className="error">{errors.apkFile}</span>}
              </div>

              {/* Release Notes Field */}
              <div className="form-field">
                <label htmlFor="releaseNotes">Release Notes:</label>
                <textarea
                  id="releaseNotes"
                  name="releaseNotes"
                  value={formData.releaseNotes}
                  onChange={handleChange}
                  placeholder="Release Notes"
                />
                {errors.releaseNotes && <span className="error">{errors.releaseNotes}</span>}
              </div>

              {/* Track Selection Field */}
              <div className="form-field">
                <label htmlFor="track">Track:</label>
                <select
                  id="track"
                  name="track"
                  value={formData.track}
                  onChange={handleChange}
                >
                  <option value="production">Production</option>
                  <option value="beta">Beta</option>
                  <option value="alpha">Alpha</option>
                  <option value="internal">Internal</option>
                </select>
              </div>

              {/* Submit Button */}
              <button type="submit" disabled={isLoading}>Submit</button>
            </form>
            {isLoading && <div>Loading...</div>}
            {isLoading && <div className="loader"></div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
